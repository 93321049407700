/* eslint-disable */
import { defineAsyncComponent } from 'vue'
export const registerComponents = ( app:any ) => {
  // DESKTOP
  app.component("TopBar", defineAsyncComponent(() => import("@/components/home/TopBar.vue")))
  app.component("HeadSection", defineAsyncComponent(() => import("@/components/home/HeadSection.vue")))
  app.component("BlocksSection", defineAsyncComponent(() => import("@/components/home/BlocksSection.vue")))
  app.component("BlockSectionWithSlider", defineAsyncComponent(() => import("@/components/home/BlockSectionWithSlider.vue")))
  app.component("ImageSlider", defineAsyncComponent(() => import("@/components/home/ImageSlider.vue")))
  app.component("Footer", defineAsyncComponent(() => import("@/components/home/Footer.vue")))
  app.component("BottomSection", defineAsyncComponent(() => import("@/components/home/BottomSection.vue")))
  app.component("BlackBlocksSection", defineAsyncComponent(() => import("@/components/home/BlackBlocksSection.vue")))
  app.component("TextSlider", defineAsyncComponent(() => import("@/components/home/TextSlider.vue")))
  // MOBILE
  app.component("TopBarMobile", defineAsyncComponent(() => import("@/components/home/mobile/TopBar.vue")))
  app.component("HeadSectionMobile", defineAsyncComponent(() => import("@/components/home/mobile/HeadSection.vue")))
  app.component("BlocksSectionMobile", defineAsyncComponent(() => import("@/components/home/mobile/BlocksSection.vue")))
  app.component("BlockSectionWithSliderMobile", defineAsyncComponent(() => import("@/components/home/mobile/BlockSectionWithSlider.vue")))
  app.component("ImageSliderMobile", defineAsyncComponent(() => import("@/components/home/mobile/ImageSliderMobile.vue")))
  app.component("FooterMobile", defineAsyncComponent(() => import("@/components/home/mobile/Footer.vue")))
  app.component("BottomSectionMobile", defineAsyncComponent(() => import("@/components/home/mobile/BottomSection.vue")))
  app.component("BlackBlocksSectionMobile", defineAsyncComponent(() => import("@/components/home/mobile/BlackBlocksSectionMobile.vue")))
  app.component("TextSliderMobile", defineAsyncComponent(() => import("@/components/home/mobile/TextSliderMobile.vue")))
}
